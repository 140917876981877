<template>
    <b-card>
        <!-- media -->
        <b-media no-body>
            <b-media-aside>
                <b-link @mouseenter="hovering = true" @mouseleave="hovering = false" class="user-profile">
                    <b-img ref="previewEl"
                           rounded
                           :src="profileimage"
                           height="80"
                           width="100" />
                    <span @click="removeProfileImage" v-if="isImage"
                          variant="flat-danger"
                          class="remove-icon"
                          v-show="hovering"> <feather-icon icon="ArchiveIcon" /></span>
                </b-link>
                <!--/ avatar -->
            </b-media-aside>

            <b-media-body class="mt-0 ml-75">
                <!-- upload button -->
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          size="sm"
                          class="mb-75 mr-75"
                          @click="$refs.refInputEl.$el.click()">
                    Upload
                </b-button>

                <b-form-file ref="refInputEl"
                             v-model="profileFile"
                             accept=".jpg, .png"
                             :hidden="true"
                             plain
                             @input="inputImageRenderer" />
                <!--/ upload button -->
                <!--/ reset -->
                <b-card-text>Allowed JPG or PNG. Max size of 800kB</b-card-text>

            </b-media-body>
        </b-media>
        <!--/ media -->
        <!-- form -->
        <validation-observer ref="simpleRules">
            <b-form class="mt-2">
                <b-row>
                    <b-col sm="6">
                        <b-form-group>
                            <label for="FirstName">First Name<span class="required">*</span></label>

                            <validation-provider #default="{ errors }"
                                                 name="FirstName"
                                                 rules="required">
                                <b-form-input v-if="UserInfo"
                                              v-model="UserInfo.FirstName"
                                              placeholder="FirstName...."
                                              name="FirstName"
                                              :state="errors.length > 0 ? false : null" />
                                <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                            </validation-provider>
                        </b-form-group>

                        <b-form-input v-if="UserInfo" v-model="UserInfo.Id" hidden />

                        <b-form-input v-if="UserInfo" v-model="UserInfo.AspNetUserId" hidden />

                        <b-form-input v-if="UserInfo" v-model="UserInfo.UserId" hidden />
                    </b-col>
                    <b-col sm="6">
                        <b-form-group>
                            <label for="LastName">Last Name<span class="required">*</span></label>
                            <validation-provider #default="{ errors }"
                                                 name="LastName"
                                                 rules="required">
                                <b-form-input v-if="UserInfo"
                                              v-model="UserInfo.LastName"
                                              name="LastName"
                                              placeholder="LastName....."
                                              :state="errors.length > 0 ? false : null" />
                                <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group label="Email" label-for="Email">
                            <b-form-input v-if="UserInfo"
                                          v-model="UserInfo.Email"
                                          name="Email"
                                          placeholder="Email"
                                          readonly />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12 text-right">
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="primary"
                                  class="mt-2 mr-1"
                                  @click="AddEditUserInfo()">
                            <feather-icon icon="SaveIcon" size="18" />
                            Save
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card>
</template>

<style scoped>

    .user-profile {
        position: relative;
    }

    .remove-icon {
        right: 0;
        padding: 5px;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(0,0,0,0.8);
        color: #ea5455;
    }

    .b-link:hover .remove-icon {
        display: block;
    }
</style>


<script>
    import {
        BFormFile,
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BAlert,
        BCard,
        BCardText,
        BMedia,
        BMediaAside,
        BMediaBody,
        BLink,
        BImg,
    } from "bootstrap-vue";
    import Ripple from "vue-ripple-directive";
    import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";

    import { ref } from "@vue/composition-api";
    import jwt from "@/auth/jwt/useJwt";
    import { $themeConfig } from "@themeConfig";
    import { ValidationProvider, ValidationObserver } from "vee-validate";
    import {
        required,
        email,
        confirmed,
        url,
        between,
        alpha,
        integer,
        password,
        min,
        digits,
        alphaDash,
        length,
    } from "@validations";
    import axis from "axios";

    import { extend } from 'vee-validate';
    extend('required', {
        message: 'This Field is required',
    });
    extend('email', {
        message: 'Invalid email address',
    });

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            BButton,
            BForm,
            BImg,
            BFormFile,
            BFormGroup,
            BFormInput,
            BRow,
            BCol,
            BAlert,
            BCard,
            BCardText,
            BMedia,
            BMediaAside,
            BMediaBody,
            BLink,
        },
        directives: {
            Ripple,
        },
        props: {
            generalData: {
                type: Object,
                default: () => { },
            },
        },
        data() {
            return {
                loading: false,
                optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
                profileFile: null,
                UserInfo: null,
                isImage: false,
                hovering: false,
                WebAPIURL: $themeConfig.app.apiURL,
                required,
                profileimage: null,
                ImageMaxSize: $themeConfig.app.ProfileImageMaxSize,
                userData: JSON.parse(localStorage.getItem('User_Name')),
            };
        },
        mounted() {
            this.getUserInfo();

        },
        methods: {
            resetForm() {
                this.optionsLocal = JSON.parse(JSON.stringify(this.generalData));
            },
            async getUserInfo() {
                var self = this;

                this.loading = true;
                const access_token = await jwt.getToken();

                var headers = {
                    Authorization: "bearer " + access_token,
                };
                var axios = require("axios");
                axios
                    .get("" + this.WebAPIURL + "/api/ProfileApi/AddEditUserInfo", {
                        headers,
                    })
                    .then((response) => {
                        self.UserInfo = response.data;
                        if (response.data.ProfileImage != "" && response.data.ProfileImage != null) {
                            self.profileimage =
                                "" +
                                this.WebAPIURL +
                                "/Images/ProfilePics/" + response.data.UserId + "/" +
                                response.data.ProfileImage;
                            self.isImage = true;
                        }
                        else {
                            self.profileimage = "" + this.WebAPIURL + "/Images/profile.png";
                            self.isImage = false;
                        }
                        self.loading = false;

                    });
            },
            async AddEditUserInfo() {
                const access_token = await jwt.getToken();
                this.$refs.simpleRules.validate().then((success) => {
                    if (success) {
                        var self = this;

                        this.loading = true;

                        var axios = require("axios");
                        var qs = require("qs");
                        var data = qs.stringify({
                            FirstName: self.UserInfo.FirstName,
                            LastName: self.UserInfo.LastName,
                            Email: self.UserInfo.Email,
                            Id: self.UserInfo.Id,
                            AspNetUserId: self.UserInfo.AspNetUserId,
                            UserId: self.UserInfo.UserId,
                        });

                        var config = {
                            method: "post",
                            url:
                                "" + this.WebAPIURL + "/api/ProfileApi/AddEditUserInfo?" + data,
                            headers: {
                                Authorization: "bearer " + access_token,
                            },
                            data: data,
                        };

                        axios(config)
                            .then(function (response) {
                                if (response.data == true) {

                                    localStorage.setItem('User_Name', JSON.stringify(self.UserInfo.FirstName + " " + self.UserInfo.LastName));

                                    document.querySelector("#lblUserName").innerHTML = self.UserInfo.FirstName + " " + self.UserInfo.LastName;
                                    self.$swal(
                                        "Success",
                                        "",
                                        "success"
                                    );
                                    self.loading = false;
                                }
                            })
                            .catch(function (error) {
                                self.loading = false;
                            });
                    }
                });
            },
            async inputImageRenderer() {
                const access_token = await jwt.getToken();
                var ImageFormat = this.profileFile.type;
                ImageFormat = ImageFormat.replace("image/", "").toLowerCase();
                this.loading = true;
                if (this.profileFile.size / 1024 <= this.ImageMaxSize) {
                    if (
                        ImageFormat == "jpg" ||
                        ImageFormat == "jpeg" ||
                        ImageFormat == "png" ||
                        ImageFormat == "gif"
                    ) {
                        let formdata = new FormData();
                        formdata.append("file", this.profileFile);

                        axis
                            .post(
                                "" + this.WebAPIURL + "/api/UserProfile/UploadProfileImage",
                                formdata,
                                {
                                    headers: {
                                        Authorization: "bearer " + access_token,
                                        "Content-Type": "multipart/form-data",
                                    },
                                }
                            )
                            .then((response) => {
                                var result = JSON.parse(response.data);
                                if (result.name == "success") {
                                    this.isImage = true;
                                    this.profileimage = "" + this.WebAPIURL + "/" + result.path;
                                   
                                    document.querySelector("#navbarimage").querySelector(".b-avatar-img").firstElementChild.src = this.profileimage;
                                } else {
                                    this.$swal(
                                        "Warning",
                                        result.name.toUpperCase(),
                                    );
                                }

                                this.loading = false;
                            })
                            .catch((error) => {
                                this.errorMessage = error.message;
                                this.loading = false;
                                // console.error("There was an error!", error);
                            });
                    } else {
                        this.$swal(
                            "Warning",
                            "Image Format is not correct.",
                            );
                        this.loading = false;
                    }
                } else {
                    this.$swal(
                        "Warning",
                        "Image Size should not be more than 800KB",
                    );

                    this.loading = false;
                }
            },

            async removeProfileImage() {
                const access_token = await jwt.getToken();
                // Make an API call to remove the image from the database
                this.$swal({
                    title: 'Delete Profile Image',
                    text: "Are you sure?",
                    //icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Delete',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,


                }).then(result => {
                    if (result.value) {

                        this.loading = true;

                        axis.get(
                            "" + this.WebAPIURL + "/api/UserProfile/RemoveProfileImage",
                            {
                                headers: {
                                    Authorization: "bearer " + access_token,
                                },
                            }
                        )
                            .then((response) => {
                                if (response.data == true) {
                                    this.profileimage = "" + this.WebAPIURL + "/Images/profile.png";
                                    this.isImage = false;
                                    document.querySelector("#navbarimage").querySelector(".b-avatar-img").firstElementChild.src = this.profileimage;
                                }

                                this.loading = false;
                            })
                            .catch((error) => {
                                this.loading = false;
                            });
                    }
                });
            }
        },
        setup() {
            const refInputEl = ref(null);
            const previewEl = ref(null);

            //const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

            return {
                refInputEl,
                previewEl,
                //inputImageRenderer,
            };
        },
    };
</script>
