<template>
    <b-card>
        <!-- form -->
        <validation-observer ref="simpleRules">
            <b-form id="passwordForm">
                <b-row>
                    <!-- old password -->
                    <b-col md="6">
                        <b-form-group>
                            <label for="OldPassword"> Current Password<span class="required"> *</span></label>
                            <validation-provider #default="{ errors }"
                                                 name="OldPassword"
                                                 rules="required">
                                <b-input-group class="input-group-merge">


                                    <b-form-input id="account-old-password"
                                                  v-model="passwordValueOld"
                                                  name="old-password"
                                                  :type="passwordFieldTypeOld"
                                                  placeholder="Current Password"
                                                  :state="errors.length > 0 ? false:null" />
                                    <b-input-group-append is-text>
                                        <feather-icon :icon="passwordToggleIconOld"
                                                      class="cursor-pointer"
                                                      @click="togglePasswordOld" />
                                    </b-input-group-append>
                                </b-input-group>
                                <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                            </validation-provider>

                        </b-form-group>
                    </b-col>
                    <!--/ old password -->
                </b-row>
                <b-row>
                    <!-- new password -->
                    <b-col md="6">
                        <b-form-group>
                            <label for="NewPassword"> New Password<span class="required"> *</span></label>
                            <validation-provider #default="{ errors }"
                                                 name="NewPassword"
                                                 rules="required|verify_password"
                                                 vid="password">
                                <b-input-group class="input-group-merge">
                                    <b-form-input id="account-new-password"
                                                  v-model="newPasswordValue"
                                                  :type="passwordFieldTypeNew"
                                                  name="new-password"
                                                  placeholder="New Password"
                                                  :state="errors.length > 0 ? false:null" />
                                    <b-input-group-append is-text>
                                        <feather-icon :icon="passwordToggleIconNew"
                                                      class="cursor-pointer"
                                                      @click="togglePasswordNew" />
                                    </b-input-group-append>
                                </b-input-group>
                                <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <!--/ new password -->
                    <!-- retype password -->
                </b-row>
                <b-row>
                    <b-col md="6">
                        <b-form-group>
                            <label for="RetypePassword"> Retype New Password<span class="required"> *</span></label>
                            <validation-provider #default="{ errors }"
                                                 name="RetypePassword"
                                                 rules="required|confirmed:password">
                                <b-input-group class="input-group-merge">
                                    <b-form-input id="account-retype-new-password"
                                                  v-model="RetypePassword"
                                                  :type="passwordFieldTypeRetype"
                                                  name="retype-password"
                                                  placeholder="New Password"
                                                  :state="errors.length > 0 ? false:null" />
                                    <b-input-group-append is-text>
                                        <feather-icon :icon="passwordToggleIconRetype"
                                                      class="cursor-pointer"
                                                      @click="togglePasswordRetype" />
                                    </b-input-group-append>
                                </b-input-group>
                                <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <!--/ retype password -->
                    <!-- buttons -->
                    <b-col cols="12 text-right">
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="primary"
                                  class="mt-1 mr-1"
                                  @click="changePassword">
                            <feather-icon icon="SaveIcon" size="18" />
                            Update
                        </b-button>

                    </b-col>
                    <!--/ buttons -->
                </b-row>
            </b-form>
        </validation-observer>
    </b-card>
</template>
<style>
    #passwordForm .popup-validation {
        right: 60px;
    }

    .popup-validation small {
        opacity: 1 !important;
        left: -10rem;
    }
</style>
<script>
    import {
        BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import jwt from '@/auth/jwt/useJwt'

    import { $themeConfig } from '@themeConfig'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import {
        required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
    } from '@validations'

    import { extend } from 'vee-validate';
    extend('required', {
        message: 'This Field is required',
    });
    extend('email', {
        message: 'Invalid email address',
    });
    extend('verify_password', {
        message: "Make sure it's at least 7 Characters including UpperCase, LowerCase letter, Number and Special Character.",
        validate: value => {
            var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*.])(?=.{7,})");
            return strongRegex.test(value);
        }
    });

    extend('confirmed', {
        message: "Password and Confirm Password doesn't match",
    });


    export default {
        components: {
            BButton,
            BForm,
            BFormGroup,
            BFormInput,
            BRow,
            BCol,
            BCard,
            BInputGroup,
            BInputGroupAppend,
            ValidationProvider,
            ValidationObserver,
        },
        directives: {
            Ripple,
        },
        data() {
            return {
                passwordValueOld: '',
                newPasswordValue: '',
                RetypePassword: '',
                passwordFieldTypeOld: 'password',
                passwordFieldTypeNew: 'password',
                passwordFieldTypeRetype: 'password',
                required,
                WebAPIURL: $themeConfig.app.apiURL,

            }
        },
        computed: {
            passwordToggleIconOld() {
                return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            },
            passwordToggleIconNew() {
                return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            },
            passwordToggleIconRetype() {
                return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            },
        },
        methods: {
            togglePasswordOld() {
                this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
            },
            togglePasswordNew() {
                this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
            },
            togglePasswordRetype() {
                this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
            },
            async changePassword() {
                const access_token = await jwt.getToken();
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {

                        if (this.newPasswordValue != this.RetypePassword) {
                            this.$swal('Error',
                                'New password and Retype New Password value mismatch.' +
                                'Please enter New password and Retype New Password  as a same. ',
                                'error'
                            );

                        } else {

                            var self = this;
                            this.loading = true;

                            var axios = require('axios');
                            var qs = require('qs');
                            var data = qs.stringify({
                                "OldPassword": self.passwordValueOld,
                                "NewPassword": self.newPasswordValue,

                            });

                            var config = {
                                method: 'post',
                                url: '' + this.WebAPIURL + '/api/user/UpdatePassword?' + data,
                                headers: {
                                    'Authorization': 'bearer ' + access_token
                                },
                                data: data
                            };

                            axios(config)
                                .then(function (response) {
                                    if (response.data == true) {
                                        self.$swal('Success',
                                            '',
                                            'success'
                                        );
                                        self.loading = false;
                                    } else {

                                        self.$swal('Error',
                                            'Current Password is Wrong.',
                                            'error'
                                        );
                                    }

                                })
                                .catch(function (error) {
                                    // console.log(error);
                                    self.loading = false;
                                });
                        }
                    }
                });


            },

        },
    }
</script>
