<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="simpleRules">

    <b-form>
      <b-row>
        <!-- bio -->
        <b-col cols="12">
          <b-form-group
          >
            <label for="Address"> Address<span class="required"> *</span></label>
             <validation-provider
                #default="{ errors }"
                name="Address"
                rules="required"
              >
            <b-form-input
              id="address"
                           v-if="Address"
              v-model="Address.AddressLine1"
              placeholder="Please Enter Your Address Here"
                          :state="errors.length > 0 ? false:null"
            />
            <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>

           </validation-provider>
          </b-form-group>
        </b-col>
        <!--/ bio -->

        <!-- birth date -->
        <b-col md="6">
          <b-form-group label="Country" label-for="Country">


                                <select class="form-control"
                                        name="Country"
                                        id="Country"
                                        v-if="Address"
                                        v-model="Address.CountryId">
                                    <option value="0">Select Country</option>
                                    <option v-for="country in Countries"
                                            :value="country.Id" > 
                                            {{country.Name}}

                                    </option>
                                </select>
                            </b-form-group>
        </b-col>
        <!--/ birth date -->

        <!-- Country -->
        <b-col md="6">
          <b-form-group
          >
            <label for="City"> City<span class="required"> *</span></label>
            <validation-provider
                #default="{ errors }"
                name="City"
                rules="required"
              >
            <b-form-input
              id="City"
                           v-if="Address"
              v-model="Address.City"
              label="City"
                          placeholder="Please Enter City Name Here"
                          :state="errors.length > 0 ? false:null"
            />
            <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>

           </validation-provider>
          </b-form-group>
        </b-col>
        <!--/ Country -->

        <!-- website -->
        <b-col md="6">
          <b-form-group
            label-for="State/Province or Region"
            label="State"
          >
            <b-form-input
              id="state"
                           v-if="Address"
              v-model="Address.Province"
              placeholder="State/Province or Region"
            />
          </b-form-group>
        </b-col>
        <!--/ website -->

        <!-- phone -->
        <b-col md="6">
          <b-form-group
          >
            <label for="Zipcode">Zip Code<span class="required"> *</span></label>
            <validation-provider
                #default="{ errors }"
                name="Zipcode"
                rules="required"
              >
            <b-form-input
              id="zipcode"
                           v-if="Address"
              v-model="Address.ZipCode"
              class="form-control"
              placeholder="Please Enter Zip Code Here"
                          :state="errors.length > 0 ? false:null"
            />
            <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>

           </validation-provider>
          </b-form-group>
        </b-col>
        <!-- phone -->

        <b-col cols="12 text-right">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mt-1 mr-1"
                      @click="saveAddress">
                <feather-icon icon="SaveIcon" size="18" />
                Save changes
            </b-button>
          
        </b-col>
      </b-row>
    </b-form>
    </validation-observer>

  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import jwt from '@/auth/jwt/useJwt'
import { $themeConfig } from '@themeConfig'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
        required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
    } from '@validations'
  import axis from 'axios'


// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
    flatPickr,
    Cleave,
  },
  directives: {
    Ripple,
  },
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      countryOption: ['USA', 'India', 'Canada'],
      localOptions: JSON.parse(JSON.stringify(this.informationData)),
      clevePhone: {
        phone: true,
        phoneRegionCode: 'US',
      },
      Address:null,
      Countries:null,
      WebAPIURL: $themeConfig.app.apiURL,
      required

    }
  },
  mounted(){
this.getAddress();
this.getCountries();

  },
  methods: {
    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.informationData))
    },
    async getAddress(){
                
                var self = this;

                this.loading = true;
                const access_token =await jwt.getToken();


                var headers = {
                    'Authorization': 'bearer ' + access_token
                };
                var axios = require('axios');
                axios.get('' + this.WebAPIURL + '/api/ProfileApi/AddEditAddress',{ headers })
                    .then((response) => {
                      console.log(response.data);
                        self.Address = response.data;
                        self.loading=false;
                  });

    },
    async getCountries(){
                
                var self = this;

        this.loading = true;
        const access_token = await jwt.getToken();


                var headers = {
                    'Authorization': 'bearer ' + access_token
                };
                var axios = require('axios');
                axios.get('' + this.WebAPIURL + '/api/ProfileApi/getCountries',{ headers })
                    .then((response) => {
                      console.log(response.data);
                        self.Countries = response.data;
                        self.loading=false;
                  });

    },
    async saveAddress(){
                       const access_token =await  jwt.getToken();
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                    

                        var self = this;
                        this.loading = true;

                        var axios = require('axios');
                        var qs = require('qs');
                        var data = qs.stringify({
                          "AddressLine1":self.Address.AddressLine1,
                          "CountryId":self.Address.CountryId,
                          "City":self.Address.City,
                          "Province":self.Address.Province,
                          "ZipCode":self.Address.ZipCode,
                          "UserId":self.Address.UserId,
                          "Id":self.Address.Id

                        });

                        var config = {
                            method: 'post',
                            url: '' + this.WebAPIURL + '/api/ProfileApi/AddEditAddress?'+data,
                            headers: {
                                'Authorization': 'bearer ' + access_token
                            },
                            data: data
                        };

                        axios(config)
                            .then(function (response) {
                              if(response.data==true){
                                self.$swal('Success',
                                            'User Address is changed successfully',
                                            'success'
                                            );
                                self.loading = false;
                                }else{

                                  self.$swal('Error',
                                            'User Address is not changed. Try again.',
                                            'error'
                                            );
                                }

                            })
                            .catch(function (error) {
                                self.loading = false;
                            });
                    }
                });
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
