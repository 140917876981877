<template>


    <b-overlay :show="loading"
               :variant="variant"
               :opacity="opacity"
               :blur="blur"
               spinner-variant="primary"
               spinner-type="grow"
               spinner-large
               class="loaderPreview"
               rounded="sm">
        <b-row v-if="(meta.breadcrumb || meta.pageTitle)"
               class="content-header">
            <b-col class="content-header-left mb-2"
                   cols="12"
                   md="6">
                <b-row class="breadcrumbs-top">
                    <b-col cols="12">
                        <h2 class="content-header-title float-left pr-1 mb-0">
                            {{ meta.pageTitle}}
                        </h2>
                        <div class="breadcrumb-wrapper">
                            <b-breadcrumb>
                                <b-breadcrumb-item to="/">
                                    <feather-icon icon="HomeIcon"
                                                  size="16"
                                                  class="align-text-top" />
                                </b-breadcrumb-item>
                                <!--<b-breadcrumb-item v-for="item in meta.breadcrumb"
                                                   :key="item.text"
                                                   :active="item.active"
                                                   :to="item.to">
                                    {{ item.text }}
                                </b-breadcrumb-item>-->
                            </b-breadcrumb>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <b-tabs vertical
                content-class="col-12 col-md-9 mt-1 mt-md-0"
                pills
                nav-wrapper-class="col-md-3 col-12"
                nav-class="nav-left">

            <!-- general tab -->
            <b-tab active>

                <!-- title -->
                <template #title>
                    <feather-icon icon="UserIcon"
                                  size="18"
                                  class="mr-50" />
                    <span class="font-weight-bold">General</span>
                </template>

                <account-setting-general v-if="options.general"
                                         :general-data="options.general" />
            </b-tab>
            <!--/ general tab -->
            <!-- change password tab -->
            <b-tab v-if="!IsEmpirelyUser">

                <!-- title -->
                <template #title>
                    <feather-icon icon="LockIcon"
                                  size="18"
                                  class="mr-50" />
                    <span class="font-weight-bold">Change Password</span>
                </template>

                <account-setting-password />
            </b-tab>
            <!--/ change password tab -->
            <!-- info -->
            <!--<b-tab>-->

                <!-- title -->
                <!--<template #title>
                    <feather-icon icon="HomeIcon"
                                  size="18"
                                  class="mr-50" />
                    <span class="font-weight-bold">Address</span>
                </template>

                <account-setting-information v-if="options.info"
                                             :information-data="options.info" />
            </b-tab>-->

            <!-- social links -->
            <!-- notification -->

        </b-tabs>
    </b-overlay>
</template>

<script>
    import {
        BRow, BCol, BTabs, BTab, BOverlay, BBreadcrumb, BBreadcrumbItem } from 'bootstrap-vue'
    import AccountSettingGeneral from '../account-setting/AccountSettingGeneral.vue'
    import AccountSettingPassword from '../account-setting/AccountSettingPassword.vue'
    import AccountSettingInformation from '../account-setting/AccountSettingInformation.vue'
    import AccountSettingSocial from '../account-setting/AccountSettingSocial.vue'
    import AccountSettingNotification from '../account-setting/AccountSettingNotification.vue'
    import jwt from '@/auth/jwt/useJwt'
    export default {
        metaInfo: {
            title: 'Landingly | Profile'
        },
        components: {
            BTabs,
            BTab,
            BRow,
            BCol,
            BOverlay,
            BBreadcrumb,
            BBreadcrumbItem,
            AccountSettingGeneral,
            AccountSettingPassword,
            AccountSettingInformation,
            AccountSettingSocial,
            AccountSettingNotification,
        },
        data() {
            return {
                loading: false,
                variant: "light",
                opacity: 0.85,
                blur: "2px",
                meta: {
                    pageTitle: 'Profile',
                    breadcrumb: [
                        {
                            text: 'Profile',
                            active: true
                        },
                    ],
                },
                options: {},
                IsEmpirelyUser: false,
            }
        },
        created() {
            const isEmpirelyUser = localStorage.getItem('IsEmpirelyUser');

            this.IsEmpirelyUser = isEmpirelyUser === 'true';
            console.log("Processed IsEmpirelyUser value (boolean): ", this.IsEmpirelyUser);
        },
        beforeCreate() {
            var subDomain = jwt.getSubDomain();
            //this.IsEmpirelyUser = localStorage.getItem('IsEmpirelyUser');
            if (subDomain == null || subDomain == "" || subDomain == 'undefined') {
                //this.$router.replace('/setupuseraccount')
                this.$router.replace('/setupuseraccount')
            }

            this.$http.get('/account-setting/data').then(res => { this.options = res.data })
        },
    }
</script>
